main {
  .near {
    .nav-images {
      figure {
        width: 90%;
      }
    }
  }
  .prices {
    table {
      width: 99%;
    }
  }
}